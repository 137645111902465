import axios from "axios";
import { TimeLine } from '@/types/time_line'
import { TimeLineComment } from "@/types/time_line_comment";

export class TimeLineRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) {
    this.url = TimeLineRepository.DOMAIN + "v1/projects/" + this.projectId + "/time_lines/";
  }

  async getList(params: { [key: string]: any }): Promise<TimeLine[]> {
    return axios.get(this.url, { params: params }).then((response) => {
      return response.data.time_lines;
    });
  }
  async get(time_line_id: number): Promise<TimeLine> {
    return axios.get(this.url + time_line_id).then((response) => {
      return response.data;
    });
  }
  async update(time_line_id: number, body: string): Promise<TimeLine> {
    return axios
      .put(this.url + time_line_id, {
        time_line: { body: body }
      }).then((response) => {
        return response.data
      })
  }
  async add(body: string): Promise<TimeLine> {
    return axios.post(this.url, {
      time_line: {
        body: body,
      },
    }).then((response) => {
      return response.data;

    })
  }
  async delete(time_line_id: number): Promise<void> {
    return axios.delete(this.url + time_line_id)

  }
  async iine(time_line_id: number): Promise<{ iine_count: number, iine_users: { user_name: string }[] }> {
    return axios.post(this.url + time_line_id + "/iine").then((response) => {
      return response.data
    })
  }
  async cancelIine(time_line_id: number): Promise<{ iine_count: number, iine_users: { user_name: string }[] }> {
    return axios.post(this.url + time_line_id + "/cancel_iine").then((response) => {
      return response.data
    })
  }
  async sendComment(time_line_id: number, body: string): Promise<TimeLineComment> {
    return axios.post(this.url + time_line_id + "/comment",
      { time_line_comment: { body: body }, project_id: this.projectId }).then((response) => {
        return response.data
      })
  }
  async updateComment(time_line_id: number, comment_id: number, body: string): Promise<TimeLineComment> {
    return axios.put(this.url + time_line_id + "/update_comment", { time_line_comment_id: comment_id, time_line_comment: { body: body } }).then((response) => {
      return response.data
    })
  }
  async deleteComment(time_line_id: number, comment_id: number): Promise<void> {
    return axios.delete(this.url + time_line_id + "/destroy_comment", { params: { time_line_comment_id: comment_id } })
  }
  async commentIine(time_line_id: number, comment_id: number): Promise<number> {
    return axios.post(this.url + time_line_id + "/comment_iine", { time_line_comment_id: comment_id }).then((response) => {
      return response.data.iine_count
    })
  }
  async commentCancelIine(time_line_id: number, comment_id: number): Promise<number> {
    return axios.post(this.url + time_line_id + "/comment_cancel_iine", { time_line_comment_id: comment_id }).then((response) => {
      return response.data.iine_count
    })
  }
}
