import { ref, computed, watch } from "vue";
import { useRoute } from 'vue-router'
import { TimeLine } from "@/types/time_line"
import { TimeLineRepository } from '@/repositories/TimeLineRepository'
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic'
export const TimeLinesLogic = () => {
    const route = useRoute();
    const projectId = computed(() => Number(route.params.project_id))
    const filterUnread = ref(false)

    const timeLineLoading = ref(false)
    const noMore = ref(false)
    const time_lines = ref<TimeLine[]>([])
    const loadTimeLineDisable = computed(() => timeLineLoading.value || noMore.value);
    // 全部入りは一緒にしたいよなぁ・・・
    const loadTimeLines = async (clear = false) => {
        timeLineLoading.value = true;
        if (clear) {
            time_lines.value = [];
            noMore.value = false;
        }
        const repository: TimeLineRepository = new TimeLineRepository(projectId.value);
        try {
            const ret_time_lines: TimeLine[] = await repository.getList({ limit: 20, offset: time_lines.value.length, unread: filterUnread.value });
            if (ret_time_lines.length == 0) {
                noMore.value = true
            }
            for (const p of ret_time_lines) {
                time_lines.value.push(p);
            }
            timeLineLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }

    };
    watch(() => route.params.project_id, () => {
        if (route.params.project_id) {
            time_lines.value = []
            noMore.value = false
            loadTimeLines();
        }
    })

    const timeLineStateReturn = () => {
        return {
            loadTimeLines,
            timeLineLoading,
            loadTimeLineDisable,
            time_lines,
            filterUnread
        }
    }
    return {
        timeLineStateReturn,
        ...timeLineStateReturn()
    }
}



